import { MATINAL_ID, VERTICAL } from '@App/libs/utils/constants/pro'
import { removeSpecialChars } from '@App/libs/utils/geral'
import axios from 'axios'

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKOFFICE_API
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const axiosJOTA = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKOFFICE_API
})

/**
 * Profile User API
 * Me - Return data from the user
 */
export const ProfileAPI = {
    me: () =>
        `${process.env.NEXT_PUBLIC_BACKOFFICE_API}/api/auth/users/profiles/me/`,
    resetPassword: (email: string) =>
        `${process.env.REACT_APP_BO_API}/auth/users/forgot-password?email=${email}&context=pro`,
    recentNewsletter: (page = '1', limit = '3') =>
        `${process.env.NEXT_PUBLIC_BACKOFFICE_API}/newsletters/campaigns/latest/?page=${page}&limit=${limit}&exclude=matinal`
}

/**
 * JOTA PRO Api
 * Return data from JOTA PRO Content
 * @param {string} slug
 * @param {string} token
 * @return {Promise}
 */
export const jotaPROApi = (slug: string, token: string) => {
    return new Promise((resolve, reject) => {
        axiosJOTA
            .get(`${process.env.NEXT_PUBLIC_PRO_NEWSLETTERS}/slug/${slug}`, {
                headers: { Authorization: token }
            })
            .then((resp) => resolve(resp))
            .catch((error) => reject(error))
    })
}

export const getLastPRONews = () => {
    return new Promise((resolve, reject) => {
        axiosJOTA
            .post(`${process.env.NEXT_PUBLIC_OPENSEARCH}`, {
                from: 0,
                size: 4,
                query: {
                    match: {
                        'source.type': 'cms.newsletter'
                    }
                },
                sort: [
                    {
                        'source.published_at': {
                            order: 'desc'
                        }
                    }
                ]
            })
            .then((resp) => resolve(resp))
            .catch((error) => reject(error))
    })
}

/**
 * Get Last Content from JOTA PRO by OpenSearch
 * @param {string} type
 * @param {number} size
 * @return {Promise}
 */
export const getLastProcontent = (type: string, size = 4) => {
    return new Promise((resolve, reject) => {
        const OPENSEARCH_API = process.env.NEXT_PUBLIC_OPENSEARCH
        const OPENSEARCH_TOKEN = process.env.NEXT_PUBLIC_OPENSEARCH_TOKEN

        type = removeSpecialChars({ str: type })

        if (!OPENSEARCH_API || !OPENSEARCH_TOKEN) return

        const query = {
            from: 0,
            size: size,
            query: {
                bool: {
                    filter: [
                        { term: { 'source.type': 'cms.newsletter' } },
                        { term: { 'vertical.slug': type } }
                    ],
                    must_not: [
                        {
                            bool: {
                                must: [
                                    { term: { 'categories.id': MATINAL_ID } },
                                    {
                                        term: {
                                            'vertical.slug':
                                                VERTICAL.TRIBUTOS.toLowerCase()
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            sort: [
                {
                    'source.published_at': {
                        order: 'desc'
                    }
                }
            ]
        }

        axiosJOTA
            .post(OPENSEARCH_API, query, {
                headers: { Authorization: OPENSEARCH_TOKEN }
            })
            .then((response) => resolve(response.data.hits.hits))
            .catch((error) => reject(new Error(error.message)))
    })
}
